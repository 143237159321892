import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { ReactComponent as ChevronUp } from '../assets/icons/chevron-up.svg';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to handle scroll visibility
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 200);
  };

  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Debugging: Check visibility state
  console.log("ScrollToTopButton visibility:", isVisible);

  return (
    <Button
      onClick={scrollToTop}
      className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
      aria-label="Scroll to Top"
    >
      <ChevronUp className="chevron-icon" />
    </Button>
  );
};

export default ScrollToTopButton;

import React from 'react';
import { Modal, Box, Typography, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.05)',
  },
});

const PartnerModal = ({ isOpen, handleClose, name, description, project_support, setup_image, logo, link }) => {
  const isDesktop = useMediaQuery('(min-width:800px)');

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="partner-modal-title">
      <Box
        sx={{
          backgroundColor: '#f4f1de',
          padding: '20px',
          borderRadius: '16px',
          maxWidth: '800px',
          margin: 'auto',
          marginTop: '10%',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          position: 'relative',
        }}
      >
        {/* Header */}
        <Typography id="partner-modal-title" variant="h2" align="center" gutterBottom>
          {name}
        </Typography>
        <hr className="horizontal-line" color='#2b2d42' />


        {isDesktop ? (
          // Desktop Layout
          <Box sx={{ display: 'flex', gap: '20px', flex: 1 }}>
            {/* Left Column */}
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              <Box
                component="img"
                src={logo}
                alt={`${name} logo`}
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  objectFit: 'contain',
                  borderRadius: '12px',
                }}
              />
              <Box
                component="img"
                src={setup_image}
                alt={`${name} Setup`}
                sx={{
                  width: '100%',
                  maxWidth: '200px',
                  objectFit: 'cover',
                  borderRadius: '12px',
                }}
              />
            </Box>

            {/* Right Column */}
            <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column', gap: '10px', position: 'relative' }}>
              <Box className="vertical-space" />
              <Typography variant="h4">{description}</Typography>
              <Box className="vertical-space" />
              <hr className="horizontal-line" color='#2b2d42' />
              <Box className="vertical-space" />
              <Typography variant="body1">{project_support}</Typography>

              {/* Button at the Bottom */}
              <StyledButton
                variant="contained"
                color="primary"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ marginTop: 'auto', marginBottom: '24px' }}
              >
                Visit {name}
              </StyledButton>
            </Box>
          </Box>
        ) : (
          // Mobile Layout
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: 1 }}>
            <Box
              component="img"
              src={logo}
              alt={`${name} logo`}
              sx={{
                width: '100%',
                maxWidth: '300px',
                objectFit: 'contain',
                borderRadius: '12px',
                alignSelf: 'center',
              }}
            />
            <Typography variant="h4">{description}</Typography>
            <Typography variant="body1">{project_support}</Typography>
            <Box
              component="img"
              src={setup_image}
              alt={`${name} Setup`}
              sx={{
                width: '100%',
                maxWidth: '300px',
                objectFit: 'cover',
                borderRadius: '12px',
                alignSelf: 'center',
              }}
            />
            <StyledButton
              variant="contained"
              color="primary"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ marginTop: 'auto', marginBottom: '24px' }}
            >
              Learn More
            </StyledButton>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default PartnerModal;

import React from 'react';
import { Link } from 'react-scroll';
import { Button } from '@mui/material';
import aboutImage from '../assets/images/about-image.png';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const AboutProject = () => {
  return (
    <div className="about-container" style={{ display: 'flex', alignItems: 'flex-start' }}>
      {/* Left Side: Image and Button */}
      <div className="about-left">
        <img src={aboutImage} alt="About Project" className="about-image" />
        <Link to="other-section" smooth={true} duration={500}>
          <StyledButton>
            Learn About Tech
          </StyledButton>
        </Link>
      </div>

      {/* Right Side: Text Content */}
      <div className="about-right" style={{ alignItems: 'flex-start', padding: '16px', paddingRight: '24px' }}>
        <h2>About Has A Brewery</h2>
        <h4>Do you want to have a Brewery? - For Free?!</h4>
        
        <p className="justify-text" style={{ alignItems: 'flex-start' }}>
          HasABrewery.com is the simple idea of creating a platform for homebrewers to collaborate and connect their brewery. <br />
          Use your small microcontroller (e.g. ESP32, Raspberry Pi, Arduino, Jetson Nano, etc) and connect your homebrew setup. HasABrewery.com provides the platform for you
          to share recipes, educate yourself, and share knowledge. It also provides free & open-source programs you can download and use
          to monitor and automate your homebrew setup. <br />
          All electronic components used in the project are available for a few dollars online from retailers with simple instructions on how to set up, configure, and connect.
        </p>
        <p className="justify-text" style={{ alignItems: 'flex-start' }}>
          For homebrewers, <b>HasABrewery.com is & stays free. </b><br />
          Commercial support for nano & microbreweries is coming soon.
        </p>
        <p className="justify-text" style={{ alignItems: 'flex-start' }}>
          After signup, you can assign a name to your brewery. Your brewery will then be available as <i>'your-name.hasabrewery.com'</i> for you & only you to access.
        </p>
        <p className="justify-text" style={{ alignItems: 'flex-start' }}>
          Down below, we have more information about the technical structure behind the scenes.
        </p>
          
      </div>
    </div>
  );
};

export default AboutProject;

import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import PartnerTile from './PartnerTile';
import partnerLogo1 from '../assets/images/partner1.png';
import partnerLogo2 from '../assets/images/partner2.png';
import partnerLogo3 from '../assets/images/partner3.png';
import partnerLogo4 from '../assets/images/partner4.png';
import setupImage1 from '../assets/images/setup-image-1.png';
import setupImage2 from '../assets/images/setup-image-2.png';
import setupImage3 from '../assets/images/setup-image-3.png';
import setupImage4 from '../assets/images/setup-image-4.png';

const partners = [
  {
    name: 'Brewtools',
    description: 'Provided a MiniUni 40+ and additional accessories.',
    logo: partnerLogo1,
    project_support: 'We actively reached out to Brewtools and asked for their support as we genueinly believe in their products & service! At Brewtools, we appreciate their modular approach of the MiniUni and MiniUni where you can build your fermenter specifically to your needs. We like that we don\'t have to pay for the stuff we don\'t need.',
    setup_image: setupImage1,
    link: 'https://brewtools.com/',
  },
  // {
  //   name: 'Partner Two',
  //   description: 'Innovative financial services.',
  //   logo: partnerLogo2,
  //   project_support: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
  //   setup_image: setupImage2,
  // },
  // {
  //   name: 'Partner Three',
  //   description: 'Experts in AI and machine learning.',
  //   logo: partnerLogo3,
  //   project_support: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
  //   setup_image: setupImage3,
  // },
  // {
  //   name: 'Partner Four',
  //   description: 'Pioneers in cloud computing.',
  //   logo: partnerLogo4,
  //   project_support: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada.',
  //   setup_image: setupImage4,
  // },
];

const Partners = () => {
  return (
    <Box className="section bg-partners" sx={{ padding: '40px' }}>
      <Typography variant="h2" align="center" gutterBottom>
        Thanks to our Partners & Supporters!
      </Typography>
      <Typography variant="h4" align="center" sx={{ marginBottom: '20px' }}>
        This project would not be possible without our supporters. Special thanks to the following companies for providing their products to us.
      </Typography>

      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        flexWrap="wrap"
        justifyContent="center"
        spacing={3}
      >
        {partners.map((partner, index) => (
          <Box
            key={index}
            sx={{
              width: { xs: '100%', sm: '45%', md: '22%' },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PartnerTile {...partner} />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default Partners;

import React from 'react';
import { Box } from '@mui/material';
import techImageDesktop from '../assets/images/tech-architecture.png';
import techImageMobile from '../assets/images/tech-architecture_mobile.png';
import { useMediaQuery } from '@mui/material';

const Other = () => {
  const isDesktop = useMediaQuery('(min-width:800px)');

  return (
    <Box id="other-section" className="other-container">
      
      <h2>Tech Architecture</h2>
      <Box className="vertical-space" />
      
      <p>
        This is an overview of the technical architecture behind the entire project.
      </p>
      <Box
        component="img"
        src={isDesktop ? techImageDesktop : techImageMobile}
        alt="Tech Architecture"
        className="other-image"
      />
      <p>
        Log into HasABrewery.com, create your profile and 'build' your digital brewery. <br />
        If you have microcontrollers (e.g. Arduino or Raspberry Pi) you can connect them
         to your brewery and monitor & control from anywhere in the world. A few button clicks. 
         That is all it takes to get started - brewing, in our case. 
         <br></br>Cheers!
      </p>
    </Box>
  );
};

export default Other;

import React from 'react';
import { Box, Stack, Button } from '@mui/material';
import { Link } from 'react-scroll';
import { styled } from '@mui/system';
import landingImage from '../assets/images/landing-image.png';
// import landingVideo from '../assets/videos/demo_video.mp4';

const StyledButton = styled(Button)({
  padding: '10px 20px',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  backgroundColor: '#2b2d42',
  color: '#f4f1de',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'background-color 0.3s ease-in-out, transform 0.2s',
  '&:hover': {
    backgroundColor: '#FABC18',
    color: '#2b2d42',
    transform: 'scale(1.15)',
  },
});

const Landing = () => {
  return (
    <Box className="section full-height bg-landing">
      {/* Left Side: Title, Subtitle, and Button */}
      <Stack className="landing-left">
        <div style={{ paddingLeft: '24px' }}>
          <Box className="vertical-space" />
          <Box className="vertical-space" />
          <Box className="vertical-space" />
          <h1>Your Own Brewery!</h1>
          <Box className="vertical-space" />
          <h4>We are building a free & open platform for homebrewers to create their own brewery.</h4>
          <Box className="vertical-space" />
          <p style={{ textAlign: 'center' }}>
            The 'Secret of Brewing' is not so secret for those in the know. <br />
            The secret is cleanliness and consistency. HasABrewery provides the best education and guidance
            to help with your cleanliness, but even more, it has the tools & platform to achieve consistency –
            which results in better beer!
          </p>
          <p style={{ textAlign: 'center' }}>
            Let's take out the guessing game and get into the brew science!
          </p>
          <Box className="vertical-space" />
          <Box className="vertical-space" />
          <Box className="vertical-space" />
          <StyledButton className="styled-button">
            <Link to="signup-section">Join Waiting List</Link>
          </StyledButton>
        </div>
      </Stack>

      {/* Right Side: Image (Temporary Replacement for Video) */}
      <Stack className="landing-right">
        {/* Commented Out Video Section */}
        {/*
        <Box
          component="video"
          src={landingVideo}
          autoPlay
          loop
          muted
          playsInline
          className="landing-video"
        />
        */}
        <Box className="vertical-space" />
        <Box
          component="img"
          src={landingImage}
          alt="Landing Image"
          className="landing-image"
          sx={{
            width: '100%',
            maxWidth: '450px',
            borderRadius: '12px',
            objectFit: 'cover',
          }}
        />
        <Box className="vertical-space" />
        <Box className="vertical-space" />
        <Box className="horizontal-stack">
          <h4 style={{ color: '#FABC18' }}>Monitor.</h4>
          <h4 style={{ color: '#4c9a2a' }}>Control.</h4>
          <h4 style={{ color: '#945a31' }}>Automate.</h4>
        </Box>
      </Stack>
    </Box>
  );
};

export default Landing;

import React, { useState } from 'react';
import { Typography, Paper, Box } from '@mui/material';
import PartnerModal from './PartnerModal';

const PartnerTile = ({ name, description, logo, project_support, setup_image, link }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  return (
    <>
      <div onClick={handleOpenModal} className="partner-tile" style={{ cursor: 'pointer' }}>
        <Paper className="tile-container" style={{ backgroundColor: '#5A5A5A', borderRadius: '16px' }}>
          <Box
            component="img"
            src={logo}
            alt={`${name} logo`}
            className="tile-image"
          />
          <Box className="vertical-space" />
          <hr className="horizontal-line2" color='#F4F1DE' />
          <Box className="vertical-space" />
          <Typography variant="h6" className="tile-title">{name}</Typography>
          <Typography variant="body2" className="tile-description" style={{ color: '#F4F1DE' }}>
            {description}
          </Typography>
        </Paper>
      </div>

      {/* Partner Modal */}
      <PartnerModal
        isOpen={isModalOpen}
        handleClose={handleCloseModal}
        name={name}
        description={description}
        logo={logo} 
        project_support={project_support}
        setup_image={setup_image}
        link={link}
      />
    </>
  );
};

export default PartnerTile;

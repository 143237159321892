import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../assets/images/hasabrewery-logo.png';

const Header = () => {
  const [opacity, setOpacity] = useState(1);

  // Function to handle scroll
  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const newOpacity = Math.max(1 - scrollTop / 200, 0);
    setOpacity(newOpacity);
  };

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box
      className="header"
      sx={{
        backgroundColor: `rgba(240, 244, 248, ${opacity})`,
        boxShadow: `0 2px 10px rgba(0, 0, 0, ${opacity * 0.1})`,
      }}
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        <img src={logo} alt="HasABrewery Logo" className="header-logo" />
      </Link>
    </Box>
  );
};

export default Header;

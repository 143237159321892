import React from 'react';
import Header from './components/Header';
import Landing from './components/Landing';
import AboutProject from './components/AboutProject';
import Partners from './components/Partners';
import Signup from './components/Signup';
import Other from './components/Other';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
import useAnalytics from './hooks/useAnalytics';

function App() {
    useAnalytics();

  return (
    <>
      {/* Header */}
      <Header />

      {/* Main Content with Top Padding */}
        <Landing />
      <div className="container">
        <AboutProject />
        <Partners />
        <Signup />
        <Other />
        <ScrollToTopButton />
      </div>

      {/* Footer (Full Width) */}
      <Footer />
    </>
  );
}

export default App;
